import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import {color} from "../constants"


export default class NewsletterForm extends React.Component {
  // Since `addToMailchimp` returns a promise, you
  // can handle the response in two different ways:

  // Note that you need to send an email & optionally, listFields
  // these values can be pulled from React state, form fields,
  // or wherever.  (Personally, I recommend storing in state).
  constructor() {
    super();
    this.state = {
      FNAME: "",
      LNAME: "",
      email: "",
      success: null
    };
  }

  _handleChange = e => {
    console.log({
        [`${e.target.name}`]: e.target.value,
    })
    this.setState({
        [`${e.target.name}`]: e.target.value,
    })
  }



  // 1. via `.then`
  _handleSubmit = e => {
    e.preventDefault();
    addToMailchimp(this.state.email, this.state) // listFields are optional if you are only capturing the email address.
    .then(data => {
      // I recommend setting data to React state
      // but you can do whatever you want (including ignoring this `then()` altogether)
      console.log(data)
      this.setState({success: true});
    })
    .catch(() => {
      // unnecessary because Mailchimp only ever
      // returns a 200 status code
      // see below for how to handle errors
    })
  }

  // 2. via `async/await`
  // _handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const result = await addToMailchimp(email, listFields)
  //   // I recommend setting `result` to React state
  //   // but you can do whatever you want
  // }

  render () {
    return (
      <div css={css`max-width: 350px;`} >
      <h5 css={css`
        margin: 0;
        font-size: 1em;
      `}>Newsletter abonnieren</h5>

      <p css={css`
        margin: 0 0 1em 0;
        font-size: 0.9em;
      `}>Bleiben Sie informiert! Wir benachrichtigen Sie über neue Veranstaltungen bequem per Mail.</p>
      
      {
        this.state.success
        ? 
        <p css={css`
          margin: 2em auto 1em auto;
          font-size: 0.9em;
          color: ${color.secondary};
          text-align: center;
        `}>Danke fürs abonnieren!</p>
        :
        <form onSubmit={this._handleSubmit}
        css={css`
          display: flex;
          flex-direction: column; 
          max-width: 250px;
          & > input[type='text'], input[type='email'] {
            font-size: 1em;
            border-color: ${color.primary};
            border-width: 1px;
            border-radius: 2px;
            padding: 0.3em;
            margin: 0.5em 0;
            & ::placeholder {
              color: ${color.primary};
            }
          }

          & > input[type='submit'] {
            width: 100%;
            padding: 0.3em 0;
            font-size: 1em;
            margin: 0.5em auto;
          }
        `}>
        <input
            type="email"
            onChange={this._handleChange}
            placeholder="E-Mail Adresse"
            name="email"
            required
        />
        <input
            type="text"
            onChange={this._handleChange}
            placeholder="Vorname"
            name="FNAME"
            required
        />
        <input
            type="text"
            onChange={this._handleChange}
            placeholder="Nachname"
            name="LNAME"
            required="true"
        />
        
        { (this.state.email == "" || this.state.FNAME == "" || this.state.LNAME == "") 
          ? <input type="submit" disabled/>
          : <input type="submit"/>}
        
      </form>
      }
      
      </div>
    )
  }
}