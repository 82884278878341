export const color = {
    primary: '#22548B',
    primaryDark: '#0C325C',
    secondary: '#F28F5F',
    secondaryDark: '#ed773e',
    light: '#FFFFFF',
    dark: '#444444'
};

export const spacing = {
    xs: '10px',
    small: '20px',
    medium: '40px',
    big: '80px'
}

export const screen = {
    xsPhone: '440px',
    tablet: '980px',
    laptop: '1280px',
    desktop: '1920px',
}