import React, { setState } from "react"
import { graphql, useStaticQuery, Link } from 'gatsby'
import Image from "gatsby-image"
import useToggle from "./useToggle"

import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import {color, spacing, screen} from "../constants"

import backgroundImage from "../images/gml-bg-5.png"
import MobileNav from "../components/mobileNav"
import SEO from "./seo"

const underline = css`
  text-decoration: underline;
`

const Wrapper = styled("div")`
  margin: 0;
  width: 100%;
  z-index: 1;
  /* background-color: ${color.primary}; */
  
  min-height: '100vh';
  max-width: '100vw';

  
  @media (min-width: ${screen.tablet}) {
    display: flex;
    justify-content: center;
    padding-top: ${spacing.big};
    padding-bottom: ${spacing.medium};
  }
  @media (min-width: ${screen.desktop}) {
    padding-top: 5%;
  }
  
`

const Layout = ({children, title, description}) => {
  const data = useStaticQuery(
    graphql`
      query Layout {
        gmlLogo: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "gml-logo-tight.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
        gmlLogoMobile: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "gml-logo-mobile.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
      }
    `
  )

  // const state = {
  //   dateOpen: false,
  //   ticketsOpen: false,
  // };


  const BackgroundImage = styled(Image)`
    z-index: -1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  `

  return (
    <Wrapper>
      <Global styles={css`
          html {
            margin: 0px;
            height: 100%;
            width: 100%;
            font-size: 18px;
          }
          // Background Image
          body::before {            
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0%; // adjust bg image position
            filter: blur(3px);
            z-index: -1;
            background: 
              linear-gradient(to bottom, rgba(34, 84, 139, 0.4), ${color.primary} 50%), 
              url(${backgroundImage}) 0 0 no-repeat;
            background-size: cover;
            background-position: 50% 50%;
          }
          body {
            background: ${color.primary};
            margin: 0;
            font-family: 'Cairo';
            font-weight: normal;
            font-style: normal;
            color: ${color.dark};

          }
          h1 {
            color: ${color.secondary};
            font-weight: normal;
            position: relative;
            display: inline-block;
            margin-top: 0;
            margin-bottom: ${spacing.xs};
          }
          h1:after {
            position: absolute;
            content: '';
            height: 2px;
            bottom: -1px; 
            margin: 0 auto;
            left: 0;
            right: 0;
            width: auto;
            background: ${color.secondary};
          }
          h4 {
            line-height: 1;
            margin: ${spacing.small} ${spacing.small} 0 ${spacing.small};
            color: ${color.primary};
            text-align: center;
            font-size: 1.5rem;
          }
        `}
      />
      <SEO title={title} description={description} />

      {/* Mobile Header */}
      <div css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: ${spacing.small} ${spacing.xs};
        
        @media (min-width: ${screen.xsPhone}) {
          margin: ${spacing.small} ${spacing.medium};
        }
        @media (min-width: ${screen.tablet}) {
          display: none;
        }
      `}>
        <Link to="/">
        <Image 
          fluid={data.gmlLogoMobile.childImageSharp.fluid}
          alt="Gesllschaft für Musik und Literatur Kreuzlingen"
          css={css`
            width: 250px;
          `}
           />
        </Link>
        <MobileNav />
      </div>


      <div css={css`
        /* margin: auto; */
        display: block;

        @media (min-width: ${screen.tablet}) {
          max-width: 1170px;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex;
        }

      `}>
        {children}
      </div>
      <aside css={css`
          display: none; 
          @media (min-width: ${screen.tablet}) {
            display: block;
            flex: 0 1 250px;
            color: ${color.light};
            margin-right: ${spacing.medium};
          }
        `}>
        <Link to="/">
        <Image 
          fluid={data.gmlLogo.childImageSharp.fluid}
          alt="Gesllschaft für Musik und Literatur Kreuzlingen"
          css={css`
            max-width: 230px;
          `}
           />
        </Link>
        <nav css={css`

          /* display: flex;
          flex-direction: column; */

          a {
            display: inline-block;
            text-decoration: none;
            background-color: ${color.light};
            color: ${color.primary};
            padding: 0.3rem 0.68em;

            font-size: 1.3rem;

            border: 1px ${color.light} solid;
            margin: ${spacing.small} 0 0 0;
            transition: 0.2s;

            &:hover {
              color: ${color.light};
              background-color: rgba(255,255,255,0.2);
            }

            &[aria-current="page"] {
              color: ${color.light};
              background-color: transparent;
              
              &:hover {
              background-color: rgba(255,255,255,0.2);
              }
            }

          }
          
        `}>
          <ul css={css`
            margin: ${spacing.small} 0 0 0;
            padding: 0;

            li {
              list-style-type: none;
              margin: 0;
              padding: 0;
            }
          `}>
            <li><Link to="/">Aktuell</Link></li>
            <li><Link to="/saison-2425/">Saison 24/25</Link></li>
            <li><Link to="/mitglied-werden/">Mitglied werden</Link></li>
            <li><Link to="/ueber-gml/">Über GML</Link></li>
            <li><Link to="/kontakt/">Kontakt</Link></li>
          </ul>
        </nav>
      </aside>      
    </Wrapper>
  )

//   <div
//   css={css`
//   padding: 32px;
//   background-color: hotpink;
//   font-size: 24px;
//   border-radius: 4px;
//   &:hover {
//     color: red;
//   }
// `}
// >
//   Hover to change color.
// </div>

}

export default Layout
